<template>
  <div>
    <video
      v-if="selfHostedVideoSrc || shopifyVideo || isInstagramVideo"
      :loop="true"
      :controls="false"
      :autoplay="true"
      :playsinline="true"
      :disablepictureinpicture="true"
      :muted="true"
      crossorigin="anonymous"
      controlslist="nodownload noplaybackrate"
      :style="{ objectFit: 'cover', borderRadius: rounded ? '12px' : '0px', height: '100%' }"
    >
      <source v-for="source in getSmallestSizeVideoSource()" :key="source.url" :src="source.url" :type="source.mimeType">
    </video>

    <video
      v-else
      :loop="true"
      :controls="false"
      :autoplay="true"
      :playsinline="true"
      :disablepictureinpicture="true"
      :muted="true"
      crossorigin="anonymous"
      controlslist="nodownload noplaybackrate"
      :ref="`videoRef-${publicId}`"
      :style="{ objectFit: 'cover', borderRadius: rounded ? '12px' : '0px',  height: '100%' }"></video>
  </div>
</template>

<script>
import {videoPlayer} from "cloudinary-video-player";
import { tryGetObjectProperty } from '@/helpers/object';

export default {
  name: 'SimpleVideoPlayer',
  props: {
    cloudName: String,
    publicId: String,
    sourceConfig: Object,
    scene: Object,
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    instagramSrc() {
      const instagramSrc = this.scene.video?.instagram_src;

      if (instagramSrc && instagramSrc.length > 0) {
        return instagramSrc;
      }

      return null
    },
    isInstagramVideo() {
      return this.instagramSrc !== null;
    },
    shopifyVideo() {
      const shopifyVideo = this.scene.video?.shopify_video;

      if (shopifyVideo && shopifyVideo.sources && shopifyVideo.sources.length > 0) {
        return shopifyVideo;
      }

      return null
    },
    selfHostedVideoSrc() {
      const selfHostedVideo = this.scene.video?.self_hosted_video;

      if (!selfHostedVideo) {
        return null;
      }

      const shopifySources = tryGetObjectProperty(selfHostedVideo, 'shopify_sources');

      if (shopifySources && shopifySources.length > 0) {
        return shopifySources[0].url;
      }

      let src = tryGetObjectProperty(selfHostedVideo, 'src');

      if (!src) {
        return null;
      }

      return src.replace('reeltok-uploads.s3.us-west-1.amazonaws.com', 'd3l6cvllw26ini.cloudfront.net');
    },
  },
  data() {
    return {
      player: null,
      playerConfig: {
        loop: true,
        muted: true,
        controls: false,
        bigPlayButton: false,
        responsive: true,
        aspectRatio: '9:16',
        fluid: true,
      },
    }
  },

  mounted() {
    if (this.selfHostedVideoSrc === null && this.shopifyVideo === null && this.isInstagramVideo === false) {
      this.initCloudinaryVideoPlayer();
    }
  },

  beforeDestroy() {
    this.player && this.player.dispose();
  },

  methods: {
    getSmallestSizeVideoSource() {
      if (this.isInstagramVideo) {
        return [{ url: this.instagramSrc, mimeType: 'video/mp4' }];
      }

      if (this.selfHostedVideoSrc) {
        return [{ url: this.selfHostedVideoSrc, mimeType: '' }];
      }

      let minSize = 1000000000;
      let smallestSizeSourceIndex = 0;

      this.shopifyVideo.sources.forEach((source, index) => {
        if (source.height < minSize) {
          minSize = source.height;
          smallestSizeSourceIndex = index;
        }
      });

      return [this.shopifyVideo.sources[smallestSizeSourceIndex]];
    },
    initCloudinaryVideoPlayer() {
      this.player = videoPlayer(this.$refs[`videoRef-${this.publicId}`], {
        cloud_name: this.cloudName,
        // controls: true,
        // playsInline: true,
        // disablePictureInPicture: true,
        // muted: true,
        // autoplay: false,
        ...this.playerConfig
      });

      // this.player.delivery(quality(50))
      // {sourceTypes: ["webm/vp9", "mp4/h265", "mp4"]}
      this.player.source(this.publicId);
    },
  }
}
</script>